import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import TagCard from '../../components/TagCard';

import SidebarWrapper from '../../components/PageLayout/Sidebar';
import PostCard from '../../components/PostCard';
import SEO from '../../components/Seo';


const Blog = ({ data }) => (
  <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SEO
        title="Blog"
        description=""
        path="blog"
      />
      <SidebarWrapper>

      <div className="marginTopTitle">
          <h1 className="titleSeparate">Topics</h1>

          <Row gutter={[20, 20]}>
          <ul>

            <li><a href='#sectionG1'>Grammar 1</a> (Coming Soon)</li>
            <li><a href='#sectionG2'>Grammar 2: Subject-Verb Agreement</a></li>
            <li><a href='#sectionG3'>Grammar 3: Neither</a></li>
            <li><a href='#sectionG4'>Grammar 4: If</a></li>
            <li><a href='#sectionG5'>Grammar 5: Relative Pronoun</a></li>
            <li><a href='#sectionG6'>Grammar 6: Subject-Verb Agreement 2</a></li>
            <li><a href='#sectionG7'>Grammar 7: Infinitive and Greund</a></li>
            <li><a href='#sectionG8'>Grammar 8: Question Tag</a></li>
            <li><a href='#sectionG9'>Grammar 9: Pronoun</a></li>
            <li><a href='#sectionG10'>Grammar 10: Since</a></li>

            <li><a href='#sectionS1'>Synthesis 1: Noun</a></li>
            <li><a href='#sectionS2'>Synthesis 2: Reported Speech</a></li>
            <li><a href='#sectionS3'>Synthesis 3: Neither</a></li>
            <li><a href='#sectionS4'>Synthesis 4: Unless</a></li>
            <li><a href='#sectionS5'>Synthesis 5: Relative Pronoun</a></li>

            <li><a href='#sectionS6'>Synthesis 6: Passive</a></li>
            <li><a href='#sectionS7'>Synthesis 7: Continuous</a></li>
            <li><a href='#sectionS8'>Synthesis 8: Have Had</a></li>
            <li><a href='#sectionS9'>Synthesis 9: Plural to Singular</a></li>
            <li><a href='#sectionS10'>Synthesis 10: No Sooner</a></li>
          
          </ul>

          </Row>
        </div>
        <br />

        <div id='sectionG1' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 1</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g1 && data.g1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />



         <div id='sectionG2' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 2: Subject-Verb Agreement</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g2 && data.g2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />

        <div id='sectionG3' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 3: Neither</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g3 && data.g3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


<div id='sectionG4' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 4: If</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g4 && data.g4.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />

        <div id='sectionG5' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 5: Relative Pronoun</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g5 && data.g5.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />

        <div id='sectionG6' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 6: Subject-Verb Agreement 2</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g6 && data.g6.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />



        <div id='sectionG7' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 7: Infinitive and Greund</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g7 && data.g7.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionG8' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 8: Question Tag</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g8 && data.g8.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />



        <div id='sectionG9' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 9: Pronoun</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g9 && data.g9.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionG10' className="marginTopTitle">
          <h1 className="titleSeparate">Grammar 10: Since</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.g10 && data.g10.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />



        <div id='sectionS1' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 1: Noun</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s1 && data.s1.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />

        <div id='sectionS2' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 2: Reported Speech</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s2 && data.s2.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionS3' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 3: Neither</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s3 && data.s3.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionS4' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 4: Unless</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s4 && data.s4.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />

        <div id='sectionS5' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 5: Relative Pronoun</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s5 && data.s5.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionS6' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 6: Passive</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s6 && data.s6.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />



        <div id='sectionS7' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 7: Continuous</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s7 && data.s7.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionS8' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 8: Have Had</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s8 && data.s8.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />

        <div id='sectionS9' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 9: Plural to Singular</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s9 && data.s9.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


        <div id='sectionS10' className="marginTopTitle">
          <h1 className="titleSeparate">Synthesis 10: No Sooner</h1>
        </div>

 <Row gutter={[20, 20]}>
          { 
            data.s10 && data.s10.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
             

                
                <Col key={key} xs={12} sm={12} md={12} lg={8}>
                

                  <TagCard 
                      img={val.node.frontmatter.cover.childImageSharp.fluid.src}
                      name={val.node.frontmatter.title}
                      description={val.node.frontmatter.excerpt}
                      color='Black'
                      data={val}
                    />

                </Col>


              ))
            
          }
        </Row>

        <br />
        <br />


      </SidebarWrapper>
    </Layout>
  </Layout>
);

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
{
  g1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g1"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

g2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g2"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g3"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g4"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g5: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g5"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g6: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g6"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g7: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g7"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g8: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g8"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g9: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g9"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  g10: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "g10"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s1: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s1"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s2: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s2"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s3: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s3"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s4: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s4"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s5: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s5"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s6: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s6"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s7: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s7"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s8: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s8"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s9: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s9"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

  s10: allMarkdownRemark(sort: {fields: [frontmatter___title], order: ASC}, filter: {fileAbsolutePath: {regex: "/index.md$/"}, frontmatter: {level: {eq: 6}, subject: {eq: "English"}, topic: {eq: "s10"}}}) {
    edges {
      node {
        frontmatter {
          date
          path
          title
          tags
          excerpt
          subject
          cover {
              childImageSharp {
                fluid(maxWidth: 288) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
      }
    }
  }

}
`;

export default Blog;
